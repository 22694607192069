.Contact{
    background-color: rgb(223, 223, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 10px 30px 10px;
}

.Contact>div{
    display: flex;
    text-align: center;
}

.Contact>div>img{
    height: 40px;
    width: 40px;
}