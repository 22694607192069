.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0 40px 0;
    background-color: #F7F7F7;
    /* border: 1px solid black; */
}

.about__title{
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 30px;
}

.about__details{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  min-width: 300px;
  width: 80%;
}

.about__detail{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 20px;
    border-radius: 8px;
}

.about__details_header{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  color: rgb(39, 65, 77);
}

.about__pcImages{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.about__image{
  /* height: 40%; */
  width: 40%;
  min-width: 200px;
  margin: 10px;
}

.about__title>img{
  width: 35px;
  height: 35px;
}