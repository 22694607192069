.bussinessProfile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #2E151B;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f3ffde, #8c4c5b);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,#f3ffde, #fafff8 ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 50px 40px 50px 40px;
}

.bussinessProfile__title{
    font-size: xx-large;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    color: rgb(214, 0, 0);
}

.bussinessProfile__imageDiv{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.bussinessProfile__image{
    width: 300px;
    height: 300px;
}