.youtube{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #2E151B;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3a2026, #8c4c5b);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,#3a2026, #8c4c5b ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 50px 0 50px 0;
}

.youtube__title{
    font-size: xx-large;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    color: white;
}

.youtube__playlist{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* border: 1px solid black; */
    padding: 10px 0 10px 0;
}

.youtube__eachPlaylist{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40%;
    min-width: 320px;
    border: 1px solid black;
    padding: 10px;
    border-radius: 7px;
    margin: 10px;
    background-color: #cee0e1;
    text-decoration: none;
    color: black;
}

.youtube__eachPlaylist:hover{
    text-decoration: none;
    background-color:  rgb(201, 201, 201);;
    color: black;
}

.youtubeThumb1{
    width: 90%;
    height: 30vh;
}

.youtube__eachDetail{
    font-size: large;
    font-weight: bold;
    text-transform: uppercase;
}

.youtube__titleImage{
    width: 90%;
}