
.header__options{
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background-color: rgb(72, 82, 128);
    /* height: 50px; */
    padding: 10px 0 10px 0;
    /* border: 1px solid red; */
}

.header__optionsBtn{
    flex: 1;
    margin: 5px 10px 5px 10px;
    padding: 5px;
    border-radius: 5px;
    min-width: 150px;
    /* width: 100%; */
    text-align: center;
    font-size: large;
    color: white;
    text-decoration: none;
    border: 1px solid rgb(167, 167, 167);
}

.header__optionsBtn:hover{
    color: aliceblue;
    background-color: rgb(96, 105, 143);
    text-decoration: none;
}

.allTitle{
    font-family: cursive;
}