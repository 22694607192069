.header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    background-color: antiquewhite;
    padding-bottom: 20px;
}

.header__name {
    display: flex;
    flex: 1;
    font-size: 40px;
    flex-direction: column;
    /* border: 1px solid purple; */
    align-items: flex-end;
    padding-right: 15px;
    text-align: left;
}

.header__tagline {
    font-size: medium;
    /* padding-right: 20px; */
    flex: 1;
}

.header__profilePicContainer{
    flex:1;
    margin: 15px 0 0 15px;
}

.header__profilePic{
    width: 100px;
    border-radius: 20px;
}