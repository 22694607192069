.books{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #2E151B;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3a2026, #8c4c5b);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,#3a2026, #8c4c5b ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 50px 0 50px 0;
}

.books__title{
    font-size: xx-large;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    color: white;
}

.books__images{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.books__each{
    width: 15%;
    min-width: 150px;
    margin: 10px;
    color: white;
    text-align: center;
}

.books__eachBook{
    width: 15%;
    min-width: 140px;
    margin: 10px;
    height: 20%;
    min-height: 210px;
}
