.votersList{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(233, 220, 168);
    padding: 20px 0 20px 0;
}

.inner{
    display: flex;
    flex-direction: column;
    width: 95%;
    line-height: 1.5em;
}

.voterList_box{
    border: 1px solid black;
    padding: 1px 4px 1px 4px;
    margin: 0 1px 0 1px;
    background-color: red;
    border-radius: 5px;
}

.decorativeLine{
    align-self: center;
}

blink {
    -webkit-animation: 1s linear infinite condemned_blink_effect; /* for Safari 4.0 - 8.0 */
    animation: 1s linear infinite condemned_blink_effect;
  }
  
  /* for Safari 4.0 - 8.0 */
  @-webkit-keyframes condemned_blink_effect {
    0% {
      visibility: hidden;
    }
    50% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }
  
  @keyframes condemned_blink_effect {
    0% {
      visibility: hidden;
    }
    50% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }

  .inner>span{
      word-break: break-all;
        white-space: normal;
        color: green;
  }