.CovidFight{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #2E151B;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f3ffde, #8c4c5b);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right,#f3ffde, #fafff8 ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 50px 40px 50px 40px;
}

.CovidFight__title{
    font-size: xx-large;
    font-weight: bold;
    margin-bottom: 30px;
    text-align: center;
    color: rgb(214, 0, 0);
}

.CovidFight__images{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.CovidFight__image{
    width: 30%;
    height: 30%;
    /* min-height: 130px; */
    min-width: 130px;
    margin: 5px;
    border-radius: 20px;
}

.CovidFight1__images_roller{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* overflow-x: scroll; */
    width: 100%;
}

.CovidFight1__image_roll{
    width: 40%;
    height: 40%;
    max-width: 300px;
    max-height: 300px;
    margin: 5px;
    border: 1px solid rgba(155, 153, 153, 0.6);
    border-radius: 10px;
}